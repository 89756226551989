import React, { Component, useContext } from 'react';
import _ from 'lodash';
import { AppContainer } from '@prcosystems/react-common';
import { stringToBoolean } from 'deep-cuts';
import { connect } from 'react-redux';
import { onload } from 'redux-load-api';
import Helmet from 'react-helmet';
import cn from 'classnames';
import { Alert, Button, ProgressBar } from 'react-bootstrap';
import store from '../redux/store';
import { getMessaging } from '../redux/modules/messaging';
import Info from '../components/Info';
import Notifs from '../components/Notifs';
import UserSnap from '../components/UserSnap';
import { getDeployment } from '../redux/modules/deployment';
import { deleteAuth } from '../redux/modules/auth';
import { AuthContext } from '../components/Auth';
import { TasksBadge } from '../components/TasksBadge';
import './App.css';

class AppErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: null };
  }

  componentDidCatch(error, info) {
    console.error(error);
    console.log(info);
    return { hasError: true, errorMessage: error.toString() };
  }

  dismissError = () => this.setState({ hasError: false, errorMessage: null });

  render() {
    return <App {...this.props} {...this.state} dismissError={this.dismissError} />;
  }
}

export const App = props => {
  const { info, loading, children, version, hasError, errorMessage, dismissError } = props;

  const { logout, userId } = useContext(AuthContext);

  const token = userId || (logout ? 'unknown' : undefined);

  return (
    <AppContainer
      onLogoutClick={logout}
      token={token}
      apiUrl="/"
      headerChildren={
        stringToBoolean(process.env.REACT_APP_FEATURE_FLAG_WATCH_TASKS_QUEUE) ? (
          <TasksBadge />
        ) : (
          <div />
        )
      }
    >
      <Helmet title="ePRco Gateway" />
      <UserSnap />

      <div className="app-content">
        <div className={cn('container-fluid', 'page-container')}>
          {info && info.message && (
            <div className="container">
              <Info className="info" />
            </div>
          )}
          {loading ? (
            <div style={{ position: 'sticky', top: 0, zIndex: 1 }}>
              <div style={{ position: 'relative', overflow: 'visible' }}>
                <div style={{ position: 'absolute', overflow: 'visible', width: '100%' }}>
                  <ProgressBar now={100} active={true} />
                </div>
              </div>
            </div>
          ) : null}
          {hasError ? (
            <Alert
              className="alert-danger"
              onDismiss={dismissError}
              style={{ width: 500, margin: 'auto' }}
            >
              <h4>The application has experienced an error</h4>
              {errorMessage}
              <p>You may need to refresh the application.</p>
              <p>
                <Button bsStyle="danger" onClick={() => document.location.reload()}>
                  Take this action
                </Button>
                <span> or </span>
                <Button onClick={dismissError}>Dismiss</Button>
              </p>
              <p>
                <small>Version: {version}</small>
              </p>
            </Alert>
          ) : (
            children
          )}
          <div className="container">
            <Notifs className="notifications" namespace="global" />
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default onload(() => {
  store.dispatch(getMessaging());
  store.dispatch(getDeployment());
})(
  connect(
    state => ({
      info: state.info,
      notifs: state.notifs,
      loading: state.page.loading,
      user: state.auth.user,
      version: _.get(state.deployment, 'version', 'N/A'),
      pathname: _.get(state.routing, 'locationBeforeTransitions.pathname'),
    }),
    {
      logout: deleteAuth,
    },
  )(AppErrorBoundary),
);
